import { useState, useEffect } from 'react'
import { Form, Input, Radio, Switch, Modal, Button, message, Select } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  deleteDatasetLabelAsync,
  getDatasetAsync
} from '../../reducers/datasets/datasetSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const EditDatasetLabelModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)
  const [dataset, setDataset] = useState({} as any)
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    props.getList()
    form.resetFields()
  }
  const onFinish = async() => {
    const params = form.getFieldsValue()
    params.id = props.record._id
    setLoading(true)
    const result = await dispatch(deleteDatasetLabelAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        message.success('Successful operation');
        closeModal()
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const onChangeName = (e: any) => {
    form.setFieldsValue({ rename: e })
  }
  const layout = {
    labelCol: { span: 5},
  }

  useEffect(() => {
    if (props.visible) {
      fetchDataset()
    }
  }, [props.record._id])
  const fetchDataset = async() => {
    if (!props.record._id) {
      return
    }
    setLoading(true)
    const result = await dispatch(getDatasetAsync({
      id: props.record._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDataset(data.dataset)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          visible={props.visible}
          title="Delete Label"
          onCancel={closeModal}
          footer={[
            <Button danger htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Delete!
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="name" label="Label name" rules={[{ required: true }]}>
            <Select onChange={onChangeName}>
              {
                dataset.label && dataset.label
                .map((label: string) => <Select.Option key={label} value={label}>{label}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
}

export default EditDatasetLabelModal
