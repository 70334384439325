// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface accountPayload {
  mobile: string;
  code: string;
  mobileCountryCode: string;
  ['g-recaptcha-response']: string;
}

export function fetchAccount(payload: accountPayload) {
  return api('/api/signin', 'POST', payload)
}


export function fetchAccountByToken() {
  return api('/api/signin/token', 'POST')
}

export function fetchCaptcha() {
  return api('/api/captcha', 'GET')
}


export function sendSMSCode(payload: any) {
  return api('/api/sms/code', 'POST', payload)
}
