import { Layout, message, Spin } from 'antd';
import './WorkSpace.scss'
import { useAppDispatch } from '../../app/hooks'
import AdminSlider from './Slider'
import Dataset from '../Dataset/Dataset'
import Users from '../Users/Users'
import Maintencance from '../Maintenance/Maintenance'
import OperationPanel from '../../Client/OperationPanel/Panel'
import DatasetDetail from '../../Client/Dataset/Dataset'
import LogFileList from '../LogFileList/LogFileList'
import DBFileList from '../DBFileList/DBFileList'
import Setting from '../Setting/Setting'
import { Switch, Route, useHistory } from "react-router-dom";
import {
  feachAccountByTokenAsync,
  restore,
  signout,
  restoreUser
} from '../../reducers/account/accountSlice';
import { useEffect, useState } from 'react';
const { Sider } = Layout;
const WorkSpace = () =>  {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    getUser()
  }, [])
  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        if (data.user.type !== 'admin') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/')
    }
  }
  return (
    <>
      <Layout>

      {loading ?
          <Layout style={{padding: '200px 0'}}>
            <Spin size="large" />
          </Layout> : <>
        <Sider className="label-admin-slider">
          <AdminSlider getUser={getUser} />
        </Sider>
        <Layout>
          <Switch>
            <Route path="/admin/maintenance/log">
              <LogFileList></LogFileList>
            </Route>
            <Route path="/admin/maintenance/db">
              <DBFileList></DBFileList>
            </Route>
            <Route path="/admin/maintenance">
              <Maintencance></Maintencance>
            </Route>
            <Route path="/admin/users">
              <Users />
            </Route>
            <Route path="/admin/setting">
              <Setting />
            </Route>
            <Route path="/admin/panel/:id">
              <OperationPanel />
            </Route>
            <Route path="/admin/:id">
              <DatasetDetail />
            </Route>
            <Route path="/admin">
              <Dataset />
            </Route>
          </Switch>
        </Layout></>}
      </Layout>
    </>
  )
}


export default WorkSpace;