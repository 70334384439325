import { Form, Input, Button, message, Modal } from 'antd';
import md5 from 'js-md5'
import { useAppDispatch } from '../app/hooks'
import { useState } from 'react'
import { resetPasswordAsync } from '../reducers/users/usersSlice'
const Setting = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      setLoading(true)
      const params: any = {
        oldPassword: md5(values.oldPassword),
        newPassword: md5(values.newPassword)
      }
      const result = await dispatch(resetPasswordAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation')
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

      <Form.Item
        label="Old Password"
        name="oldPassword"
        rules={[
          {
            required: true,
            message: 'Please input your old password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="New password"
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('oldPassword') !== value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password cannot be the same as the old password'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm new password "
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: 'Please input confirm password!',

          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 16,
        }}
      >
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Setting