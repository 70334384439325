import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message, Select, Tag, Space, Table, Row, Col } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import { DeleteOutlined } from '@ant-design/icons';
import { datasetObject } from '../../reducers/users/usersAPI'
import { putUserPremissionsAsync, } from '../../reducers/users/usersSlice'
import { getALlDatasetsAsync } from '../../reducers/datasets/datasetSlice'
import _ from 'lodash'
import './Users.scss'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
  showDeleteConfirm: Function;
}
const UsserPermissions = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [datasets, setDatasets] = useState([])
  const [data, setData] = useState([] as Array<datasetObject>)
  const [dataMap, setDataMap] = useState({} as any)
  useEffect(() => {
    if (props.visible === true) {
      fetchList()
      form.setFieldsValue({
        dataset: '',
        username: props.record.username
      })
      // user`s set
    }
  }, [props.visible])
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    props.getList()
  }
  const onFinish = async() => {
    const params = {
      id: props.record._id,
      datasets: data
    }
    setLoading(true)
    const result = await dispatch(putUserPremissionsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        message.success('Successful operation');
        closeModal()
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const fetchList = async() => {
    setLoading(true)
    const result = await dispatch(getALlDatasetsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        const tempMap: any = {}
        data.datasets.forEach((d: any) => {
          tempMap[d._id] = d.name
        })
        setDatasets(data.datasets)
        setDataMap(tempMap)
        setData(props.record.datasets && props.record.datasets.filter((d:any) => tempMap[d.datasetId]) || [])
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }
  const pushDataset = async () => {
    const obj: datasetObject = {
      datasetId: form.getFieldValue('dataset'),
      write: true,
      export: true
    }
    const newData:Array<datasetObject> = _.cloneDeep(data)
    newData.push(obj)
    setData(newData)
    form.setFieldsValue({
      dataset: ''
    })
  }
  const editDataset = (index: number, key: string, value: boolean) => () => {
    const newData:Array<any> = _.cloneDeep(data)
    newData[index][key] = value
    setData(newData)
  }
  const removeDataset = (index: number) => () => {
    const newData:Array<any> = _.cloneDeep(data)
    newData.splice(index, 1)
    setData(newData)
  }
  const layout = {
    labelCol: { span: 5},
  }
  const columns = [
    {
      title: 'Dataset',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => <span data-id={record.datasetId}> { dataMap[record.datasetId] } </span>,
    },
    {
      title: 'Write',
      dataIndex: 'write',
      key: 'write',
      render: (text: boolean, record: any, index: number) =>
        <Tag style={{ cursor: 'pointer' }} color={ !text ? 'magenta' : 'green'} onClick={editDataset(index, 'write', !text)}>{text.toString()}</Tag>
    },
    {
      title: 'Export',
      dataIndex: 'export',
      key: 'export',
      render: (text: boolean, record: any, index: number) =>
        <Tag style={{ cursor: 'pointer' }} color={ !text ? 'magenta' : 'green'} onClick={editDataset(index, 'export', !text)}>{text.toString()}</Tag>
    },
    {
      title: 'Operations',
      key: 'Operations',
      render: (text: string, record: any, index: number) => (
        <Space size="middle">
          <Button onClick={() =>
            props.showDeleteConfirm(removeDataset(index), `Are you sure delete this dataset: ${dataMap[record.datasetId]}?`)}
            danger type="primary" size={'small'}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ]
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          width='600px'
          className="add-user-modal"
          visible={props.visible}
          title={props.record._id ? `Set password: ${form.getFieldValue('username')}` :"Add User"}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Ok
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="username" label="Username" rules={[{ required: true }]}>
            <Input disabled={!!props.record._id} />
          </Form.Item>
          <Form.Item label="Permissions" rules={[{ required: true }]}>
            <Row gutter={8}>
              <Col span={19}>
                <Form.Item
                  name="dataset"
                  noStyle
                >
                  <Select>
                    {
                      // 判断是否已添加
                      datasets
                      .filter((dataset:any) => data.map((d) => d.datasetId).indexOf(dataset._id) ! == -1)
                      .map((dataset: any) => <Select.Option key={dataset._id} value={dataset._id}>{dataset.name}</Select.Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Button onClick={pushDataset} style={{width: '100%'}}>Add</Button>
              </Col>
            </Row>
          </Form.Item>
          <Table rowKey="datasetId"
            size="small" scroll={{y: '250px'}} bordered columns={columns}
            dataSource={data} pagination={false} />
        </Modal>
      </Form>
    </>
  );
}

export default UsserPermissions
