
import { Menu, Modal, message, Dropdown, Card } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { useEffect, useState } from 'react'
import RenameModel from '../../Client/Workspace/RenameModal'
import SetIconModal from '../../Client/Workspace/SetIconModal'
import { getALlDatasetsAsync } from '../../reducers/datasets/datasetSlice'
import qs from 'query-string'
import { createFromIconfontCN, RocketOutlined, SettingOutlined, PushpinOutlined, ExclamationCircleOutlined, LogoutOutlined, DatabaseOutlined, UsergroupAddOutlined, ApartmentOutlined } from '@ant-design/icons';
import {
  signout,
  selectUser
} from '../../reducers/account/accountSlice';
import {
  deleteSearchAsync,
} from '../../reducers/users/usersSlice'
import config from '../../config/config'
const IconFont = createFromIconfontCN({
  scriptUrl: `${config.domain}/static/iconfont.js`
});
const AdminSlider = (props: any) =>  {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [all, setAll] = useState(false)
  const [record, setRecord] = useState({})
  const [renameModalVisible, setRenameModalVisible] = useState(false)
  const [iconVisible, setIconVisible] = useState(false)

  const showDeleteConfirm = (fuc: Function, title: string) => {
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        fuc()
      },
      onCancel() {
      },
    })
  }
// todo
  const deleteSearch = (id: string) => async() => {
    const params = {
      id: id,
    }
    const result = await dispatch(deleteSearchAsync(params))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        message.success('Successful operation')
        props.getUser()
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }

  const rename = (record: any) => {
    setRecord(record)
    setRenameModalVisible(true)
  }

  const setIcon = (record: any) => {
    setRecord(record)
    setIconVisible(true)
  }
  const signOut = () => {
    Modal.confirm({
      title: 'Confirm to log out? ',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        history.push('/')

      },
      onCancel() {
      },
    })
  }
  useEffect(() => {
  }, [])
  return (
    <>
    <Card>
      <RocketOutlined />Label Admin
    </Card>
    <Menu
    defaultSelectedKeys={[location.pathname]}
    mode="inline"
    >
    <RenameModel
      record={record}
      setRecord={setRecord}
      visible={renameModalVisible}
      setVisible={setRenameModalVisible}
      getList={props.getUser}
    />
    <SetIconModal
      record={record}
      setRecord={setRecord}
      visible={iconVisible}
      setVisible={setIconVisible}
      getList={props.getUser}
    />

      <Menu.Item key="/admin/" icon={<DatabaseOutlined />}><Link to="/admin/"> Datasets</Link></Menu.Item>
      <Menu.SubMenu key="Pinned" title="Pinned" icon={<PushpinOutlined />}>
        {
          user.searchArr
            ?.map((search: any, index: number) =>
            <Menu.Item key={`/admin/${search.datasetId}?${qs.stringify({
              mode: search.mode,
              advanced: search.advanced || '',
              expression: search.expression || '',
              searchStr: search.searchStr || ''
            })}$index=${index}`}>
              <Dropdown overlay={(
                  <Menu>
                    <Menu.Item onClick={() => rename(search)} key="1">Rename</Menu.Item>
                    <Menu.Item onClick={() => setIcon(search)} key="2">Set Icon</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="3"
                      onClick={() => showDeleteConfirm(deleteSearch(search.id),
                      `Delete ${search.name}?`)}>Delete</Menu.Item>
                  </Menu>
                )} trigger={['contextMenu']}>
                <Link to={`/admin/${search.datasetId}?${qs.stringify({
                  mode: search.mode,
                  advanced: search.advanced || '',
                  expression: search.expression || '',
                  original_filename: search.original_filename || '',
                  searchStr: search.searchStr || ''
                })}&index=${index}`}>
                  { search.icon ? <IconFont type={search.icon} /> : <PushpinOutlined />}  {search.name}</Link>
              </Dropdown>
            </Menu.Item>)
        }
      </Menu.SubMenu>
      <Menu.Item key="/admin/users" icon={<UsergroupAddOutlined />}><Link to="/admin/users"> Users</Link></Menu.Item>
      <Menu.Item key="/admin/maintenance" icon={<ApartmentOutlined /> }>
      <Link to="/admin/maintenance">Maintenance</Link></Menu.Item>
      <Menu.Item icon={<SettingOutlined />} key="/admin/setting">
      <Link to="/admin/setting">Settings</Link>
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={signOut} key="logout">Logout</Menu.Item>
    </Menu>
    </>
  )
}

export default AdminSlider;