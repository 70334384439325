const _ = require('lodash')
var config = {
  webUrl: 'admin.labelme.org',
  // domain: 'http://localhost:8001'
  domain: 'https://admin.labelme.org'
}

try {
  /* eslint vars-on-top:0 */
  var configProd = require('./config.prod.js');
  if (configProd) {
    _.assign(config, configProd);
  }
} catch (e) {
  // 无需处理
}

module.exports = config