import { Layout, Card, Skeleton, Tooltip, PageHeader, Space, Image, Modal, Tag, Button, Divider, Collapse, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks'
import { ExclamationCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  getLabelAysnc,
  deleteLabelAysnc,
  deleteLabelChildAysnc
} from '../../reducers/labels/labelSlice'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import AddLabelModal from './AddLabelModel'
import dayjs from 'dayjs'
import _ from 'lodash'
import './Panel.scss'
const { Content, Sider } = Layout;
const { Panel } = Collapse;
const OperationPanel = (props: any) =>  {
  const dispatch = useAppDispatch()
  const histroy = useHistory()
  const { id } = (useParams() as any)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({} as any)
  const [record, setRecord] = useState({})
  const location: any = useLocation()
  const [addLabelVisible, setAddLableVisible] = useState(false)
  useEffect(() => {
    fetchData()
  }, [id])
  const fetchData = async() => {
    let params: any = {}
    params.id = id
    setLoading(true)
    const result = await dispatch(getLabelAysnc(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.label)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const deleteLabelChilld = (name: string) => async () => {
    let params: any = {}
    params.id = id
    params.name = name
    setLoading(true)
    const result = await dispatch(deleteLabelChildAysnc(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchData()
        message.success('Successful operation');
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }

  const deleteLabel = async() => {
    let params: any = {}
    params.id = id
    setLoading(true)
    const result = await dispatch(deleteLabelAysnc(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        message.success('Successful operation');
        histroy.goBack()
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }


  const showDeleteConfirm = (fuc: Function, title: string) => {
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        fuc()
      },
      onCancel() {
      },
    })
  }
  const changeKey = (key: any) => {
    console.log(key)
  }

  const addLabel = (record: any) => {
    setRecord(record)
    setAddLableVisible(true)
  }
  const removeLabel = (e: any) => {
    e.preventDefault()
  }
  const retunTag = (obj: any, color: string, tags: Array<any>, keys: Array<any>):any => {
    Object.keys(obj).map((key: string, index) => {
      keys.push(key)
      if (typeof(obj[key]) === "object") {
        retunTag(obj[key], color, tags, keys)
        keys.pop()
      } else {
        let node = (<Tag color={color} key={`${key}-${index}`}>
        {keys.join('.')}: {obj[key]}</Tag>)
        keys.pop()
        tags.push(node)
      }
    })
    return tags
  }
  const colors = ['magenta', 'red', 'volcano', 'orange', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple']
  const reverseColors = _.cloneDeep(colors).reverse()
  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title={`${data.filename ? data.filename : 'Loading...'}`}
        extra={[
          <Button key="3" onClick={() => {
            histroy.push(location.state.location)
          }}>Back</Button>,
          <Button key="1" type="primary" disabled={!data.last} onClick={() => {
            const target = {
              pathname: `/work/panel/${data.last}`,
              state: location.state
            }
            histroy.push(target)
          }}>Previous</Button>,
          <Button key="2" type="primary" disabled={!data.next} onClick={() => {
            const target = {
              pathname: `/work/panel/${data.next}`,
              state: location.state
            }
            histroy.push(target)
          }}>Next</Button>,
        ]}
      />
      <Layout>
        <Skeleton active={true} loading={loading}>
        <Content>
          <Image
              src={data.url}
            />
        </Content>
        <Sider style={{
          background: 'none',
          padding: '0 10px'
        }} width={'30%'}>
          <Collapse defaultActiveKey={['label', 'raw', 'attribute']} onChange={changeKey}>
            <Panel header="label" key="label">
              <Space size="small" wrap={true}>
              <Tag color="geekblue"
              key={'add'}
              onClick={() => addLabel({})}
              style={{cursor: 'pointer'}}
              className="site-tag-plus">
                <PlusOutlined /> New label
              </Tag>
                {
                data.label && Object.keys(data.label).map((key, index) => (
                  <Tag
                  closable
                  color={colors[Number(String(index)[String(index).length - 1])]}
                  onClose={(e) => {
                    e.preventDefault()
                    showDeleteConfirm(deleteLabelChilld(key), `Delete ${key}?`)
                  }}
                  key={`${key}-${index}`}
                    onClick={() => addLabel({
                      name: key,
                      value: data.label[key],
                      type: typeof data.label[key] === 'number' ? 'number' : 'string'
                    })}
                    style={{cursor: 'pointer'}}
                    >
                      {key}: {data.label[key]}</Tag>
                    ))}
                </Space>
            </Panel>
            <Panel header="raw" key="raw">
              <Space size="small" direction="vertical" >
                {
                data.raw && Object.keys(data.raw).length ?
                data.raw &&
                Object.keys(data.raw).map((k: string, index) => {
                  let tags = [] as any
                  let keys: Array<string> = []
                  return (<Space wrap={true} key={k}>
                    {k}:  {retunTag(data.raw[k], reverseColors[Number(String(index)[String(index).length - 1])], tags, keys)}
                  </Space>)
                })
                : 'No data'
                }
              </Space>
            </Panel>
            <Panel header="attribute" key="attribute">
              <Space direction="vertical" size="small" wrap={true}>
                 <Space>
                  original_filename: <Tag color="blue"> {data.original_filename}</Tag>
                  </Space>
                 <Space wrap={true}>
                  source:
                  {
                    data.source && data.source.map((s: string) => (<Tag key={s} color="green">{s}</Tag>))
                  }
                 </Space>
                 <Space>
                  dir: <Tag color="orange">{data.dir}</Tag>
                 </Space>
                 <Space>
                   hash: <Tag color="magenta"> {data.hash}</Tag>
                 </Space>
                 <Space>
                   Upload date: <Tag color="red"> {dayjs(data.createdAt).format('YYYY-MM-DD HH:mm')}</Tag>
                 </Space>
              </Space>
            </Panel>
            <Panel header="history" key="history">
              <div className="history-box">
                <Space direction="vertical" size="small" wrap={true} >
                    {
                    data.history && !!data.history.length ? data.history.map((ht: any, index: number) => (
                      <Tooltip key={index} color="cyan" placement="left" title={`Reason: ${ht.reason}`}>
                        <Card style={{ width: '100%'}}>
                          <Space direction="vertical" size="small" wrap={true}>
                            <Tag color="cyan">{ht.name}: {ht.text}</Tag>
                            <Space size="small" wrap={true}>
                              <Tag color="green">User: {ht.userName}</Tag>
                              <Tag color="red">{dayjs(ht.updatedAt).format('YYYY-MM-DD HH:mm')}</Tag>
                            </Space>
                          </Space>
                      </Card></Tooltip>
                  )) : 'No data'
                }
                </Space>
              </div>
            </Panel>
          </Collapse>
          <Button
          onClick={() => showDeleteConfirm(deleteLabel, `Delete ${data.filename}?`) }
          type="primary" style={{marginTop: 10}} block danger>Delete!</Button>
        </Sider>
        <AddLabelModal
          id={id}
          record={record}
          setRecord={setRecord}
          visible={addLabelVisible}
          setVisible={setAddLableVisible}
          getData={() => fetchData()}
        />
        </Skeleton>
      </Layout>
    </>
  )
}


export default OperationPanel;