import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  renameSearchAsync
} from '../../reducers/users/usersSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function
}
const RenameModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({
        name: props.record.name
      })
    }
  }, [props.visible])
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    props.getList()
  }
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const params = values
      params.id = props.record.id
      setLoading(true)
      const result = await dispatch(renameSearchAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation');
          closeModal()
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }
  return (
    <>
      <Form form={form} name="control-hooks">
        <Modal
          visible={props.visible}
          title="Rename"
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Ok
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="rename" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
}

export default RenameModal
