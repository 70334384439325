import ReactDOM from 'react-dom';
import './index.scss';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { store, history } from './app/store';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Router from './Router'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
dayjs.locale('en')
declare global {
  interface Window {
    loading: any,
    clipboardData: any
  }
}
window.loading = { status: 'idle' }
ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
