import axios from 'axios'
import _ from 'lodash'
import { store } from '../app/store';

function serialize (obj) {
  var str = ''
  if (_.isEmpty(obj)) {
    return str
  }
  for (var key in obj) {
    if (str !== '') {
      str += '&'
    }
    str += `${key}=${encodeURIComponent(obj[key])}`
  }
  return str
}

function toLowerCaseKeys (obj, types = ['string'], isExclude = false) {
  if (!obj) {
    return obj
  }
  let retObj = {}
  var typesTemp = Array.prototype.slice.apply(types)
  let isInclude = !isExclude
  Object.getOwnPropertyNames(obj).forEach((key) => {
    var value = obj[key]
    var inType = typesTemp.indexOf(typeof value) > -1
    if (isInclude && inType) {
      retObj[key.toString().toLowerCase()] = value
    } else {
      retObj[key] = value
    }
  })
  return retObj
}

/*
 * method : `DELETE`, `GET`, `HEAD`, `OPTIONS`, `POST`, or `PUT`, byte-uppercase it.
 */
export default function api (url, method = 'POST', data = {}, headers = {}, uploadFile = false, timeout = 30000) {
  var state = store.getState() || {}
  var account = state.account || {}
  var token = account.token || ''
  var defaultHeaders = {
    'accept': 'application/json',
    'authorization': token
  }
  headers = _.assign(defaultHeaders, toLowerCaseKeys(headers))

  var options = {
    credentials: 'include',
    method,
    headers,
    timeout
  }
  if (method.toUpperCase() === 'GET') {
    options.headers['content-type'] = 'application/x-www-form-urlencoded'
    data._now_ = Date.now()
    var query = serialize(data)
    if (url.indexOf('?') > -1) {
      url += `&${query}`
    } else {
      url += `?${query}`
    }
    // options.data = data
  } else {
    if (uploadFile) {
      var formData = new FormData()
      for (var key in data) {
        formData.append(key, data[key])
      }
      options.data = formData
      options.processData = false // Don't process the files
      options.contentType = false
    } else {
      options.headers['content-type'] = 'application/json'
      options.data = JSON.stringify(data)
    }
  }
  return axios(url, options)
}
