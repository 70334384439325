import { useState } from 'react'
import { Form, Input, Radio, Switch, Modal, Button, message } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  addDatasetLabelAsync,
} from '../../reducers/datasets/datasetSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const AddDatasetModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [valueType, setType] = useState('string')
  const [useEval, setUseEval] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    form.resetFields()
    props.getList()
  }
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const params = values
      params.id = props.record._id
      params.useEval = useEval
      params.valueType = valueType
      setLoading(true)
      const result = await dispatch(addDatasetLabelAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation');
          closeModal()
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }
  const onChange = (e: any) => {
    console.log('radio checked', e.target.value)
    setType(e.target.value)
    form.setFieldsValue({ value: '' })
  };
  const onSwitchChange = (checked: boolean) => {
    setUseEval(checked);
    setType('string');
    form.setFieldsValue({ value: '' })
  };
  const layout = {
    labelCol: { span: 5},
  }
  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          visible={props.visible}
          title="Add Label"
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Ok
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="name" label="Label name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Expert?">
            <Switch onChange={onSwitchChange} defaultChecked={useEval} checkedChildren='expert' unCheckedChildren='value' />
          </Form.Item>
          {
            !useEval ? (
              <Form.Item label="Value type">
                <Radio.Group onChange={onChange} value={valueType}>
                  <Radio value='string'>String</Radio>
                  <Radio value='number'>Number</Radio>
                </Radio.Group>
              </Form.Item>) : ''
          }
          <Form.Item name="value" label="Label value">
            <Input type={valueType} />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
}

export default AddDatasetModal
