import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import * as datasetAPI from './datasetAPI';
const initialState = {}

export const addDatasetAsync = createApiAsyncThunk<datasetAPI.datasetPayload>('datasets/addDataset', datasetAPI.addDataset)

export const getDatasetsAsync = createApiAsyncThunk<any>('datasets/getDatasets', datasetAPI.getDatasets)

export const getDatasetAsync = createApiAsyncThunk<any>('datasets/getDataset', datasetAPI.getDataset)

export const getALlDatasetsAsync = createApiAsyncThunk<any>('datasets/getAllDatasets', datasetAPI.getAllDatasets)

export const deleteDatasetsAsync = createApiAsyncThunk<datasetAPI.idPayload>('datasets/deleteDatasets', datasetAPI.deleteDatasets)

export const addDatasetLabelAsync = createApiAsyncThunk<datasetAPI.lablPayload>('datasets/addDatasetLabel', datasetAPI.addDatasetLabel)

export const editDatasetLabelAsync = createApiAsyncThunk<datasetAPI.editPayload>('datasets/editDatasetLabel', datasetAPI.editDatasetLabel)

export const deleteDatasetLabelAsync = createApiAsyncThunk<datasetAPI.deletePayload>('datasets/deleteDatasetLabel', datasetAPI.deleteDatasetLabel)

export const importDataAsync = createApiAsyncThunk<any>('datasets/importData', datasetAPI.importData)

export const datasetSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {}
})

export default datasetSlice.reducer;
