import {
  Layout,
  Card,
  Input,
  Form,
  Image,
  Space,
  Select,
  Pagination,
  Radio,
  Modal, Button,
  Table, Menu, Dropdown, message, Tag, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks'
import {
  DownOutlined, LoadingOutlined, PushpinOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import {
  deleteLabelsAsync,
  getLabelsAsync,
  exportLabelAsync
} from '../../reducers/labels/labelSlice'
import config from '../../config/config'
import axios from 'axios';
import { getDatasetAsync } from '../../reducers/datasets/datasetSlice'
import { addSearchAsync } from '../../reducers/users/usersSlice'
import { feachAccountByTokenAsync, restoreUser } from '../../reducers/account/accountSlice'
import _ from 'lodash'
import { useQuery } from '../../utils/query';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
const { TabPane } = Tabs;
const { Content } = Layout;
const Dataset = () =>  {
  const dispatch = useAppDispatch()
  const location:any = useLocation()
  const query:any = useQuery()
  const queryData = {
    mode: query.mode || 'simple',
    searchStr: query.searchStr || '',
    original_filename: query.original_filename || '',
    advanced: query.advanced ? JSON.parse(query.advanced): [],
    expression: query.expression || '',
    showType: (location.state && location.state.showType) || 'list',
    current: Number(location.state && location.state.current) || 1,
    pageSize: Number(location.state && location.state.pageSize) || 10,
  }
  const [suffix, setSuffix] = useState('')
  const [fileQueue, setFileQueue] = useState([] as any)
  const [fileName, setFileName] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(true)
  const [exportLoading, setExportLoading] = useState(false)
  const [mode, setMode] = useState(queryData.mode)
  const [searchStr, setSearchStr] = useState(queryData.searchStr)
  const [original_filename, setOriginal_filename] = useState(queryData.original_filename)
  const [expression, setExpression] = useState(queryData.expression)
  const [advanced, setAdvanced] = useState(queryData.advanced)
  const [data, setData] = useState([])
  const [showType, setShowType] = useState(queryData.showType)
  const [dataset, setDataset] = useState({} as any)
  const [pagination, setPagination] = useState({
    current: queryData.current,
    pageSize: queryData.pageSize,
    total: 0
  })
  const { id } = (useParams() as any)
  const history = useHistory()
  const setQueryData = () => {
    // 显示表单数据
    setMode(queryData.mode)
    setSearchStr(queryData.searchStr)
    setOriginal_filename(query.original_filename)
    setExpression(queryData.expression)
    setAdvanced(queryData.advanced)
    setPagination(pagination)
  }
  useEffect(() => {
    setQueryData()
    fetchLabelAndRaw()
    fetchListWithQuery(queryData)
  }, [id, location.search])
  const fetchListWithQuery = (query: any) => {
    fetchList(pagination, query)
  }
  const fetchList = async(params: any, query?: any) => {
    // query优先 防止setState 异步造成数据错误
    let thisMode = query ? query.mode : mode
    if (thisMode === 'simple') {
      params.searchStr = query ? query.searchStr : searchStr
      params.original_filename = query ? query.original_filename : original_filename
      params.searchStr = params.searchStr || ''
      params.original_filename = params.original_filename || ''
    } else if (thisMode === 'advanced') {
      let advParams = query ? query.advanced : advanced
      params.advanced = advParams ? JSON.stringify(advParams.filter((ad: any) => ad.key)) : []
    } else if (thisMode === 'expert') {
      let text = query ? query.expression : expression
      params.expression = text || ''
    }
    location.state = {
      current: params.current,
      pageSize: params.pageSize,
      showType: showType
    }
    history.push(location)
    params.id = id
    setLoading(true)
    const result = await dispatch(getLabelsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.labels)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const download = (suffix: string) => (txt: string) => {
    let element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt))
      element.setAttribute('download', `${new Date().getTime()}.${suffix}`)
      element.click()
  }
  const exportSH = (labels: Array<any>) => {
    const txt =
      'URL_ARRAY=('+ '\n' +
      `${labels.map((lb) => `'${lb.url}'`).join('\n')}` + '\n' +
      ')'+ '\n' +
      'NAME_ARRAY=(' + '\n' +
      `${labels.map((lb) => `'${lb.filename}'`).join('\n')}` + '\n' +
      ')'+ '\n' +
      'ELEMENTS=${#URL_ARRAY[@]}'+ '\n' +
      'for (( i=0;i<ELEMENTS;i++)); do'+ '\n' +
      ' echo "Downloading ${i+1}/${ELEMENTS}..."'+ '\n' +
      ' echo ${URL_ARRAY[${mkdii}]}'+ '\n' +
      ' curl ${URL_ARRAY[${i}]} -o ./${NAME_ARRAY[${i}]}'+ '\n' +
      ' echo "saved as ${NAME_ARRAY[${i}]}"'+ '\n' +
      ' sleep 1'+ '\n' +
      'done'
      download('sh')(txt)
      // after downloaded chmod ugo+x xxx.sh
  }
  const exportSheet = (labels: Array<any>) => {
    let table: any[]
    let results: any[] = []
    let sheet: any = {}
    table = labels.map((t, index) => {
      let doc: any = {
        "filename": t.filename,
        "original_filename": t.original_filename,
        "hash": t.hash,
        "source": t.source.join(','),
        "dir": t.dir,
        "createdAt": t.createdAt,
        "url": t.url
      }
      t.label && Object.keys(t.label).map((key) => {
        doc[`label.${key}`] = t.label[key]
      })
      t.raw && Object.keys(t.raw).map((key) => {
        doc[`raw.${key}`] = t.raw[key]
      })
      return doc
    })
    import ('../../utils/xlsx').then((xlsxUtil) => {
      import ('xlsx').then((XLSX) => {
        sheet.table = XLSX.utils.json_to_sheet(table);
        sheet.sheetName = 'all fields'
        results.push(sheet)
        const name = new Date().getTime()
        xlsxUtil.openDownloadDialog(xlsxUtil.sheet2blob(results), `export-${name}.csv`);
      })
   })
  }
  const exportLabel = (labels: Array<any>) => {
    let table: any[]
    let results: any[] = []
    let sheet: any = {}
    table = labels.map((t, index) => {
      let doc: any = {
        "filename": t.filename,
        "original_filename": t.original_filename,
        "hash": t.hash,
        "source": t.source.join(','),
        "dir": t.dir,
        "createdAt": dayjs(t.createdAt).format('YYYY-MM-DD HH:mm'),
        "url": t.url
      }
      t.label && Object.keys(t.label).map((key) => {
        doc[`label.${key}`] = t.label[key]
      })
      return doc
    })

    import ('../../utils/xlsx').then((xlsxUtil) => {
      import ('xlsx').then((XLSX) => {
        sheet.table = XLSX.utils.json_to_sheet(table);
        sheet.sheetName = 'labels'
        results.push(sheet)
        const name = new Date().getTime()
        xlsxUtil.openDownloadDialog(xlsxUtil.sheet2blob(results), `export-${name}.csv`);
      })
    })
  }
  const getURL = async (url: string) => {
    let result = {} as any
    axios.defaults.timeout = 60 * 1000;
    try {
      result = await axios.head(url)
    } catch (err) {
      console.log(err)
    }
    if (!result.headers || result.headers['content-type'].indexOf('text/html;') > -1) {
      setTimeout(() => getURL(url), 5000)
    } else {
      setLink(url)
      setExportLoading(false)
      setIsModalVisible(true)
    }
  }
  const exportList = (type: string, query?: any) => async() => {
    let params: any = {}
    let thisMode = query ? query.mode : mode
    if (thisMode === 'simple') {
      params.searchStr = query ? query.searchStr : searchStr
      params.original_filename = query ? query.original_filename : original_filename
      params.searchStr = params.searchStr || ''
      params.original_filename = params.original_filename || ''
    } else if (thisMode === 'advanced') {
      let advParams = query ? query.advanced : advanced
      params.advanced = advParams ? JSON.stringify(advParams.filter((ad: any) => ad.key)) : []
    } else if (thisMode === 'expert') {
      let text = query ? query.expression : expression
      params.expression = text || ''
    }
    params.id = id
    params.type = type
    setExportLoading(true)
    const result = await dispatch(exportLabelAsync(params))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setSuffix(data.suffix)
        setFileName(data.name)
        let queue = []
        for (let index = 0; index < data.fileCount; index++) {
          queue.push(index + 1)
        }
        setFileQueue(queue)
        let url = `${config.domain}/exports/export-${data.name}-${data.fileCount}.${data.suffix}`
        if (data.suffix === 'txt' || data.suffix === 'sh') {
          url = `${config.domain}/exports/export-${data.name}.${data.suffix}`
        }
        getURL(url)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchLabelAndRaw = async() => {
    setLoading(true)
    let result = await dispatch(getDatasetAsync({
      id: id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDataset(data.dataset)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const handleTableChange = (page: any, pageSize: any) => {
    fetchList({
      current: page,
      pageSize: pageSize
    })
  };
  const onFinish = (values: any) => {
    setAdvanced(values.advanced)
  };
  const onChangeExpression = (e: any) => {
    setExpression(e.target.value)
  }
  const changeValue = (key: string, index: number) => (value: any) => {
    let adArr = _.cloneDeep(advanced)
    adArr[index][key] = typeof value === 'string' ? value : value.target.value
    setAdvanced(adArr)
  }
  const addKV = () => {
    let adArr = _.cloneDeep(advanced)
    if (!adArr) {
      adArr = []
    }
    adArr.push({
      type: 'string',
      operator: '==',
      key: '',
      value: ''
    })
    setAdvanced(adArr)
  }
  const removeKV = (index: number) => {
    let adArr = _.cloneDeep(advanced)
    if (!adArr) {
      adArr = []
    }
    adArr = adArr.filter((ad: any, idx: number) => idx !== index)
    setAdvanced(adArr)
  }
  const showDeleteConfirm = (fuc: Function, title: string) => {
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        fuc()
      },
      onCancel() {
      },
    })
  }
  const remove = (id: string) => async () => {
    setLoading(true)
    const result = await dispatch(deleteLabelsAsync({id: id}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        fetchList(pagination)
        message.success('Successful operation')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={exportList('txt')} key="txt">
        Image URLs(.txt)
      </Menu.Item>
      <Menu.Item onClick={exportList('sh')} key="sh">
        Image URLs(.sh)
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item onClick={exportList('csv')} key="labels">
        Labels(.csv)
      </Menu.Item>
      <Menu.Item onClick={exportList('all')} key="all">
        All fields(.csv)
      </Menu.Item>
    </Menu>
  );
  const colors = ['magenta', 'red', 'volcano', 'orange', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple']
  const reverseColors = _.cloneDeep(colors).reverse()
  const columns = [
    {
      title: 'Filename',
      dataIndex: 'filename',
      width: '200px',
      render: (filename: string, record: any) => {
        const target = {
          pathname: `/${location.pathname.indexOf('admin') > -1 ? 'admin' : 'work'}/panel/${record._id}`,
          state: {
            location: location
          }
        }
        return (
        <>
          <Link
            key={target.pathname} to={target}>{filename}</Link>
        </>
      )}
    },
    {
      title: 'Origianl filename',
      width: '200px',
      dataIndex: 'original_filename',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: '200px',
      render: (source: Array<string>) => (
          source.map((s) => <Tag key={s}>{s}</Tag>
         )
      ),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      render: (label: any, record: any) => (
        <Space wrap={true}>
          {label && Object.keys(label).map((key, index) => <Tag key={key} color={colors[Number(String(index)[String(index).length - 1])]}>{key}:{label[key]}</Tag>)}
        </Space>
      ),
    },
    {
      title: 'Raw',
      dataIndex: 'raw',
      width: '30%',
      render: (raw: any, record: any) => (
        <Space wrap={true}>
          {raw && Object.keys(raw).map((key, index) => <Tag key={key} color={reverseColors[Number(String(index)[String(index).length - 1])]}>{key}</Tag>)}
        </Space>
      ),
    }
  ];
  const onSearch = (e:any) => {
    setSearchStr(e.target.value)
  }
  const onSearchOrigianlFilename = (e:any) => {
    setOriginal_filename(e.target.value)
  }
  const resetSearch = () => {
    setMode('simple')
    setSearchStr('')
    setAdvanced([])
    setExpression('')
  }
  const addSearch = async () => {
    const params: any = {
      search: {}
    }
    params.search.mode = mode
    params.search.datasetId = id
    if (mode === 'simple') {
      params.search.searchStr = searchStr
      params.search.original_filename = original_filename
    } else if (mode === 'advanced') {
      params.search.advanced = advanced ? JSON.stringify(advanced) : []
    } else if (mode === 'expert') {
      params.search.expression = expression || ''
    }
    const result = await dispatch(addSearchAsync(params))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        message.success('Successful operation')
        getUser()
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }

  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        dispatch(restoreUser({ user: data.user }))
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }

  return (
    <>
     <Content className="label-admin-content" style={{ padding: '0 10px'}}>
        <Card>
          <Tabs activeKey={mode} onChange={(key) => setMode(key)} type="card">
            <TabPane tab="Simple" key="simple">
              <Form
                layout='inline' autoComplete="off">
                <Form.Item label="Filename">
                  <Input
                    value={searchStr}
                    onChange={onSearch}
                    style={{width: '300px'}}
                    placeholder="input search text"
                  />
                </Form.Item>
                <Form.Item label="Origianl filename">
                  <Input
                    value={original_filename}
                    onChange={onSearchOrigianlFilename}
                    style={{width: '300px'}}
                    placeholder="input search text"
                  />
                </Form.Item>
                <Form.Item>
                <Button onClick={() => fetchList(pagination)}>Search</Button>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Advanced" key="advanced">
              <Form autoComplete="off">
                {advanced.map((ad: any, index: number) => (
                  <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item label="Value type"
                      style={{width: '265px'}}
                    >
                      <Radio.Group onChange={changeValue('type', index)}
                      value={advanced[index].type}>
                        <Radio value='string'>String</Radio>
                        <Radio value='number'>Number</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      style={{width: '250px'}}
                      label="Key"
                    >
                      <Select
                        value={advanced[index].key}
                        onChange={changeValue('key', index)}
                        placeholder="Key">
                        {
                          dataset && dataset.label?.map((label: string) => <Select.Option key={`label.${label}`} value={`label.${label}`}>{`label.${label}`}</Select.Option> )
                        }
                        {
                          dataset && dataset.raw?.map((key: string) => <Select.Option key={`raw.${key}`} value={`raw.${key}`}>{`raw.${key}`}</Select.Option> )
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{width: '100px'}}
                    >
                      <Select
                        value={advanced[index].operator}
                        onChange={changeValue('operator', index)}
                        >
                        <Select.Option key="==" value="==">==</Select.Option>
                        <Select.Option key="!=" value="!=">!=</Select.Option>
                        <Select.Option key=">" value=">">{'>'}</Select.Option>
                        <Select.Option key=">=" value=">=">{'>='}</Select.Option>
                        <Select.Option key="<" value="<">{'<'}</Select.Option>
                        <Select.Option key="<=" value="<=">{'<='}</Select.Option>
                        <Select.Option key="contains" value="contains">{'contains'}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{width: '200px'}}
                      label="Value"
                    >
                      <Input
                        value={advanced[index].value}
                        onChange={changeValue('value', index)} placeholder="Value" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => removeKV(index)} />
                  </Space>
                ))}
                <Button style={{marginRight: '10px' }} type="dashed" onClick={() =>
                  addKV()
                  } icon={<PlusOutlined />}>
                  Add filter
                </Button>
                <Button type="primary" onClick={() => fetchList(pagination)}>Search</Button>
              </Form>
            </TabPane>
            <TabPane tab="Expert" key="expert">
              <Form
                layout='inline' autoComplete="off">
                <Form.Item label="Expression">
                  <Input
                    value={expression}
                    onChange={onChangeExpression}
                    style={{width: '500px'}}
                    placeholder="(label.a == 1) && (label.dr >= 1) || (raw.age != 10)"
                  />
                </Form.Item>
                <Button onClick={() => fetchList(pagination)}>Search</Button>
              </Form>
            </TabPane>
          </Tabs>
        </Card>
        <Card style={{'margin': '10px 0'}}>
          <Space size="middle">
            <Button type="primary" onClick={addSearch}><PushpinOutlined /> Pin this search</Button>
            <Button onClick={resetSearch}><ReloadOutlined />Reset search</Button>
            <Dropdown.Button
              onClick={exportList('all')}
              disabled={exportLoading}
              overlay={menu}
              icon={!exportLoading ? <DownOutlined /> :   <LoadingOutlined />}
            >
              Export
            </Dropdown.Button>
          </Space>

          <Radio.Group
            style={{float: 'right'}}
            value={showType} onChange={
              (e: any) =>{
                setShowType(e.target.value)
                pagination.current = 1
                if (e.target.value === 'list') {
                  pagination.pageSize = 10
                } else {
                  pagination.pageSize = 20
                }
                setPagination(pagination)
                fetchList(pagination)
              }} optionType="button" buttonStyle="solid">
              <Radio.Button value="images"><AppstoreOutlined /></Radio.Button>
              <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
            </Radio.Group>
        </Card>
        {
          showType === 'list' ?
          <Table
            columns={columns}
            bordered
            rowKey={(record: any) => record._id}
            dataSource={data}
            pagination={false}
            loading={loading}
          />:
          <Space align="start" wrap={true}>
           { data.map((d: any) =>{
              const target = {
                pathname: `/${location.pathname.indexOf('admin') > -1 ? 'admin' : 'work'}/panel/${d._id}`,
                state: {
                  location: location
                }
              }
             return (
             <Link key={target.pathname} to={target}><Image  preview={false} key={d._id} src={d.url} width={300}  /></Link>
            )})
          }
          </Space>
        }
        <Pagination {...pagination}
          style={{
            marginTop: 20
          }}
          onChange={handleTableChange}
          showTotal={ (total) => {
            return `Total: ${total}`
          }} />
      </Content>
      <Modal width={800} title="Export info" visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
        { suffix === 'txt' || suffix === 'sh' ?
          <p> Download Link:  <a target="_blank" href={link}>{link}</a> </p>
        :
          fileQueue.map((index:number, idx: number) => {
            return <p>{index}. Download Link: <a target="_blank" href={`${config.domain}/exports/export-${fileName}-${index}.${suffix}`}>{`${config.domain}/exports/export-${fileName}-${index}.${suffix}`}</a></p>
          })
        }
      </Modal>
    </>
  )
}


export default Dataset;