import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SignIn from './SignIn/SignIn'
import AdminWorkSpace from "./Admin/Workspace/WorkSpace";
import ClientWorkSpace from './Client/Workspace/WorkSpace'
export default function Main() {
  return (
    <>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/admin">
          <AdminWorkSpace />
        </Route>
        <Route path="/work">
          <ClientWorkSpace />
        </Route>
        <Route path="/">
          <SignIn />
        </Route>
      </Switch>
    </>
  );
}
