import { Layout, message, Spin, Space } from 'antd';
import './WorkSpace.scss'
import { useAppDispatch } from '../../app/hooks'
import ClientSider from './Slider'
import Dataset from '../Dataset/Dataset'
import OperationPanel from '../OperationPanel/Panel'
import Setting from '../Setting/Setting'
import { Switch, Route, useHistory } from "react-router-dom";
import {
  feachAccountByTokenAsync,
  restore,
  signout,
  restoreUser
} from '../../reducers/account/accountSlice';
import { useEffect, useState } from 'react';
const { Sider } = Layout;
const WorkSpace = () =>  {
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const history = useHistory()
  useEffect(() => {
    getUser()
  }, [])
  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        if (data.user.type !== 'normal') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/')
    }
  }
  return (
    <>
      <Layout>
        {
          loading ?
          <Layout style={{padding: '200px 0'}}>
            <Spin size="large" />
          </Layout> : <>
          <Sider className="label-admin-slider">
            <ClientSider getUser={getUser} />
          </Sider>
          <Layout>
            <Switch>
              <Route path="/work/setting">
                <Setting />
              </Route>
              <Route path="/work/panel/:id">
                <OperationPanel />
              </Route>
              <Route path="/work/:id">
                <Dataset />
              </Route>
              <Route path="/work">
                <Dataset />
              </Route>
            </Switch>
          </Layout></>
        }
      </Layout>
    </>
  )
}


export default WorkSpace;