// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface labelPayload {
  name: string;
  labels: any;
}

export function exportLabels(payload: any) {
  return api('/api/labels/export', 'GET', payload, {}, false, 10 * 60 * 1000)
}

export function getLabels(payload: any) {
  return api('/api/labels', 'GET', payload)
}

export interface idPayload {
  id: string;
}
export function deleteLabels(payload: idPayload) {
  return api('/api/labels', 'DELETE', payload)
}


export interface idPayload {
  id: string;
}
export function getLabel(payload: idPayload) {
  return api('/api/label', 'GET', payload)
}

export interface addPayload {
  id: string;
  type: string;
  name: string;
  value?: string | undefined;
}
export function addLabel(payload: addPayload) {
  return api('/api/label', 'PUT', payload)
}


export interface deletePayload {
  id: string;
  name: string;
}
export function deleteLabelChild(payload: deletePayload) {
  return api('/api/label', 'delete', payload)
}
export function deleteLabel(payload: idPayload) {
  return api('/api/labels', 'delete', payload)
}


export interface editPayload {
  id: string;
  operation: string;
  name: string;

  rename?: string;

  expert?: boolean;
  value?: string | undefined;
}
export function editLabel(payload: editPayload) {
  return api('/api/labels', 'PUT', payload)
}

export function getAllLabels(payload: any) {
  return api('/api/labels/all', 'GET', payload)
}
