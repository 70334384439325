
import { Tabs } from 'antd';
import ResetPassword from '../../Components/ResetPassword'
const { TabPane } = Tabs

const Setting = () => {
  return (
    <Tabs style={{padding: '0 20px'}} defaultActiveKey="1">
      <TabPane tab="Reset Password" key="1"
        style={{padding: '20px 0'}}>
        <ResetPassword/>
      </TabPane>
    </Tabs>
  );
};

export default Setting