// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface userPayload {
  name: string;
  mobile: String;
  mobileCountryCode: string;
}

export function addUser(payload: userPayload) {
  return api('/api/admin/users', 'POST', payload)
}

export function getUsers(payload: any) {
  return api('/api/admin/users', 'GET', payload)
}

export function deleteUser(payload: idPayload) {
  return api('/api/admin/users', 'DELETE', payload)
}

export interface idPayload {
  id: string;
}
export function lockUser(payload: idPayload) {
  return api('/api/admin/users/lock', 'PUT', payload)
}
export function unlockUser(payload: idPayload) {
  return api('/api/admin/users/unlock', 'PUT', payload)
}

export interface datasetObject {
  datasetId: string;
  write: boolean;
  export: boolean;
}
export interface putUserPremissionsPayload {
  id: string;
  datasets: Array<datasetObject>;
}
export function putUserPremissions(payload: putUserPremissionsPayload) {
  return api('/api/admin/users', 'PUT', payload)
}



export interface searchPayload {
  id: string;
  rename?: string;
  icon?: string;
  search?: any
}
export function addSearch(payload: searchPayload) {
  return api('/api/users/search', 'POST', payload)
}




export function renameSearch(payload: searchPayload) {
  return api('/api/users/search/name', 'PUT', payload)
}


export function setIconSearch(payload: searchPayload) {
  return api('/api/users/search/icon', 'PUT', payload)
}

export interface searchIdPayload {
  id: string;
}
export function deleteSearch(payload: searchIdPayload) {
  return api('/api/users/search', 'DELETE', payload)
}




export interface passwordPayload {
  newPassword: string;
  oldPassord: string
}
export function resetPassword(payload: passwordPayload) {
  return api('/api/user/password', 'PUT', payload)
}