import { Layout, PageHeader, Skeleton, List, Card, Button, Divider, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {fetchLogsAsync } from '../../reducers/stat/statSlice'
import {selectAccount} from '../../reducers/account/accountSlice'
import config from '../../config/config'
import { AlignLeftOutlined } from '@ant-design/icons';
import { Link  } from 'react-router-dom';
const { Content } = Layout;
const { Option } = Select;
const Logs = () =>  {
  const dispatch = useAppDispatch()
  const account = useAppSelector(selectAccount)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    fetchFiles()
  }, [])

  const fetchFiles = async() => {
    setLoading(true)
    const result = await dispatch(fetchLogsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        console.log(data)
        setData(data.logs)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title="Logs"
        extra={[
          <Button key="2" onClick={() => fetchFiles()}>Refresh</Button>
        ]}
      />
      <Divider className="label-admin-divider" />
      <Content className="label-admin-content"
      style={{width: '100%', padding: '30px 30px'}}>
        <Skeleton active={true} loading={loading}>
            {
              data.map((item: string) => (
                <a key={item}  href={`${config.domain}/api/admin/download/logs/${item}?token=${account.token}`} target="view_window">
                  <Card.Grid style={{width: 205}}>
                  <AlignLeftOutlined /> {item}</Card.Grid>
                </a>
              ))
            }
        </Skeleton>
      </Content>
    </>
  )
}


export default Logs;