import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { createApiAsyncThunk } from '../thunk'

import * as statAPI from './statAPI'
export interface AccountState {
}
const initialState: AccountState = {
}

export const fetchOSStatAsync = createApiAsyncThunk<any>('account/fetchStat', statAPI.fetchStat)

export const fetchLogsAsync = createApiAsyncThunk<any>('account/fetchLogs', statAPI.fetchLogs)

export const fetchDBAsync = createApiAsyncThunk<any>('account/fetchDB', statAPI.fetchDB)

export const statSlice = createSlice({
  name: 'stat',
  initialState,
  reducers: {
  },
});

export const selectAccount = (state: RootState) => state.account;
export const selectUser = (state: RootState) => state.account.user;

export default statSlice.reducer;
