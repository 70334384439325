import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import accountSlice from '../reducers/account/accountSlice'
import datasetSlice from '../reducers/datasets/datasetSlice'
import labelSlice from '../reducers/labels/labelSlice'
import usersSlice from '../reducers/users/usersSlice'
import statSlice  from '../reducers/stat/statSlice'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import logger from 'redux-logger'
import { History, createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  account: accountSlice,
  // admin
  dataset: datasetSlice,
  labels: labelSlice,
  users: usersSlice,
  stat: statSlice
})

export const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    routerMiddleware(history),
    logger
  ])
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
