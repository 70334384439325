import { Layout, PageHeader, Space, Modal, Button, Divider, Table, Menu, Dropdown, message } from 'antd';
import { useEffect, useState, useRef } from 'react';
import AddModal from './AddUserModal'
import UserPermissionsModal from './UserPermissionsModal'
import { useAppDispatch } from '../../app/hooks'
import { DownOutlined, ExclamationCircleOutlined, LockOutlined, UnlockOutlined} from '@ant-design/icons';
import dayjs from 'dayjs'
import {
  getUsersAsync,
  lockUserAsync,
  deleteUserAsync,
  unlockUserAsync
} from '../../reducers/users/usersSlice'
import './Users.scss'
const { Content } = Layout;
const Users = () =>  {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })
  const [addModelVisible, setAddModelVisible] = useState(false)
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false)
  const [record, setRecord] = useState({})
  useEffect(() => {
    fetchList(pagination)
  }, [])

  const fetchList = async(params: any) => {
    setLoading(true)
    const result = await dispatch(getUsersAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.users)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    fetchList({ ...changePagination })
  };

  const editPassword = (record: any) => {
    setRecord(record)
    setAddModelVisible(true)
  }
  const editPermissions = (record: any) => {
    setRecord(record)
    setPermissionsModalVisible(true)
  }
  const showDeleteConfirm = (fuc: Function, title: string) => {
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        fuc()
      },
      onCancel() {
      },
    })
  }
  const remove = (id: string) => async () => {
    setLoading(true)
    const result = await dispatch(deleteUserAsync({id}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        fetchList(pagination)
        message.success('Successful operation')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const lock = (id: string) => async () => {
    setLoading(true)
    const result = await dispatch(lockUserAsync({id}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        fetchList(pagination)
        message.success('Successful operation')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const unlock = (id: string) => async () => {
    setLoading(true)
    const result = await dispatch(unlockUserAsync({id}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        fetchList(pagination)
        message.success('Successful operation')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const menu = (record: any) => (
    <Menu>
      <Menu.Item onClick={() => editPassword(record)} key="0">
        Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => editPermissions(record)}>
       Permissions
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      width: '20%',
      render: (username: string, record: any) => (
        <>
         <span>{record.lock === true ? <LockOutlined/> : ''} {username}</span>
        </>
      ),
    },
    {
      title: 'MobileCountry Code',
      dataIndex: 'mobileCountryCode',
      width: '170px'
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      width: '170px'
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      render: (createdAt: string) => (
        <>
         <span>{dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        </>
      ),
    },
    {
      title: 'Last login',
      dataIndex: 'createdAt',
      render: (createdAt: string) => (
        <>
         <span>{dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        </>
      ),
    },
    {
      title: 'Operations',
      key: 'operations',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Dropdown overlay={menu(record)} trigger={['click']}>
            <Button size={'small'} onClick={e => e.preventDefault()}>
              Set <DownOutlined />
            </Button>
           </Dropdown>
           {
             !record.lock ?
             <Button onClick={() =>
               showDeleteConfirm(lock(record._id), `Are you sure lock this user: ${record.username}?`)}
               danger size={'small'}>
              <LockOutlined />
               Lock
             </Button>:
            <Button onClick={() =>
              showDeleteConfirm(unlock(record._id), `Are you sure unlock this user: ${record.username}?`)}
              danger size={'small'}>
              <UnlockOutlined />
              Unlock
            </Button>
           }
          <Button onClick={() =>
            showDeleteConfirm(remove(record._id), `Are you sure delete this user: ${record.username}?`)}
            danger type="primary" size={'small'}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title="Users"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddModelVisible(true)} >Add</Button>,
          <Button key="2" onClick={() => fetchList({})}>Refresh</Button>
        ]}
      />
      <Divider className="label-admin-divider" />
      <Content className="label-admin-content">
        <Table
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            ...pagination,
            showTotal: (total) => {
              return `Total: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <AddModal
        record={record}
        setRecord={setRecord}
        visible={addModelVisible}
        setVisible={setAddModelVisible}
        getList={() => fetchList(pagination)}
      />
      <UserPermissionsModal
        record={record}
        setRecord={setRecord}
        visible={permissionsModalVisible}
        showDeleteConfirm={showDeleteConfirm}
        setVisible={setPermissionsModalVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Users;