// A mock function to mimic making an async request for data
import api from '../../utils/api'



export function fetchStat(payload: any) {
  return api('/api/admin/stat', 'GET', payload)
}


export function fetchLogs(payload: any) {
  return api('/api/admin/logs', 'GET', payload)
}

export function fetchDB(payload: any) {
  return api('/api/admin/db', 'GET', payload)
}

