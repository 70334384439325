import { useState, useEffect } from 'react'
import { Form, Input, Radio, Space, Modal, Drawer, Button, message } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  addLabelAysnc
} from '../../reducers/labels/labelSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  id: string;
  record: any;
  setRecord: Function;
  getData: Function;
}
const AddDatasetModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [valueType, setType] = useState('number')
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const closeModal = () => {
    props.setRecord({})
    props.setVisible(false)
    form.resetFields()
  }
  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({
        name: props.record.name,
        oldValue: props.record.value,
        type: props.record.type ||'number'
      })
      setType(props.record.type ||'number')
    }
  }, [props.visible])
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const params = values
      params.id = props.id
      setLoading(true)
      const result = await dispatch(addLabelAysnc(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation');
          closeModal()
          props.getData()
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }
  const onChange = (e: any) => {
    console.log('radio checked', e.target.value)
    setType(e.target.value)
    form.setFieldsValue({ value: '' })
  };
  const layout = {
    labelCol: { span: 7},
  }
  return (
    <>
        <Drawer
          width={400}
          visible={props.visible}
          title={props.record.name ? 'Edit Label' : 'Add Label'}
          onClose={closeModal}
          footer={[
          ]}
        >
      <Form form={form} {...layout}>

          <Form.Item name="name" label="Label name" rules={[{ required: true, message: 'Please input label name' }]}>
            <Input disabled={props.record.value !== undefined} />
          </Form.Item>
          <Form.Item label="Value type" name="type">
            <Radio.Group onChange={(e: any) => setType(e.target.value)} >
              <Radio value='string'>String</Radio>
              <Radio value='number'>Number</Radio>
            </Radio.Group>
          </Form.Item>
          {
           props.record.value !== undefined ? <Form.Item name="oldValue" label="Old value">
              <Input disabled />
            </Form.Item> : ''}
          <Form.Item name="value" label="Label value">
            <Input type={valueType} />
          </Form.Item>
          {
           props.record.value !== undefined ? <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
              <Input.TextArea  />
            </Form.Item> : ''}
          <Space>
            <Button
              onClick={onFinish} loading={loading} type="primary" >
              Ok
            </Button>
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          </Space>
      </Form>
        </Drawer>
    </>
  );
}

export default AddDatasetModal
