import { Layout, PageHeader, Space, Modal, Button, Divider, Table, Menu, Dropdown, message, Tag } from 'antd';
import { useEffect, useState } from 'react';
import AddDatasetModal from './AddDatasetModal'
import AddLabelModal from './AddLabelModal'
import DeleteLabelModal from './DeleteLabelModal'
import EditLabelModal from './EditDatasetModal'
import { useAppDispatch } from '../../app/hooks'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import {
  getDatasetsAsync,
  deleteDatasetsAsync
} from '../../reducers/datasets/datasetSlice'
import { Link } from 'react-router-dom';
const { Content } = Layout;
const Dataset = () =>  {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })
  const [addLabelVisible, setAddLableVisible] = useState(false)
  const [deleteLabeModalVisible, setDeleteLabeModalVisible] = useState(false)
  const [addDatasetModelVisible, setAddDatasetModelVisible] = useState(false)
  const [editLabelModalVisible, setEditLabelModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [record, setRecord] = useState({} as any)

  const colors = ['magenta', 'red', 'volcano', 'orange', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple']
  const fetchDatasets = async(params: any) => {
    setLoading(true)
    const result = await dispatch(getDatasetsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.datasets)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    fetchDatasets({ ...changePagination })
  };
  useEffect(() => {
    fetchDatasets(pagination)
  }, [])

  const rename = (record: any) => {
    setRecord(record)
    setAddDatasetModelVisible(true)
  }
  const addLabel = (record: any) => {
    setRecord(record)
    setAddLableVisible(true)
  }
  const editLabel = (record: any) => {
    setRecord(record)
    setEditLabelModalVisible(true)
  }
  const deleteLabel = (record: any) => {
    setRecord(record)
    setDeleteLabeModalVisible(true)
  }
  const showDeleteConfirm = (fuc: Function, title: string) => {
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        fuc()
      },
      onCancel() {
      },
    })
  }
  const remove = (id: string) => async () => {
    setLoading(true)
    const result = await dispatch(deleteDatasetsAsync({id: id}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        fetchDatasets(pagination)
        message.success('Successful operation')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const menu = (record: any) => (
    <Menu>
      <Menu.Item onClick={() => rename(record)} key="0">
        Rename
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => addLabel(record)} key="1">
        Add Label
      </Menu.Item>
      <Menu.Item onClick={() => editLabel(record)} key="2">
        Edit Label
      </Menu.Item>
      <Menu.Item onClick={() => deleteLabel(record)} key="3">
        Delete Label
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      render: (filename: string, record: any) => (
        <Link key={`/admin/${record._id}`} to={`/admin/${record._id}`}>{filename}</Link>
      )
    },
    {
      title: 'Images',
      dataIndex: 'images',
    },
    {
      title: 'Last UpdatedAt',
      dataIndex: 'updatedAt',
      render: (updatedAt: string) => (
        <>
         <span>{dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        </>
      ),
    },
    {
      title: 'Operations',
      key: 'operations',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Dropdown overlay={menu(record)} trigger={['click']}>
            <Button size={'small'} onClick={e => e.preventDefault()}>
              Config <DownOutlined />
            </Button>
           </Dropdown>
          <Button size={'small'} onClick={() => {
            setRecord(record)
            setIsModalVisible(true)
          }}>
          Labels
          </Button>
          <Button onClick={() =>
            showDeleteConfirm(remove(record._id), `Are you sure delete this dataset: ${record.name}?`)}
            danger type="primary" size={'small'}>
          Delete
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title="Datasets"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddDatasetModelVisible(true)} >Add</Button>,
          <Button key="2" onClick={() => fetchDatasets({})}>Refresh</Button>
        ]}
      />
      <Divider className="label-admin-divider" />
      <Content className="label-admin-content">
        <Modal onOk={() => setIsModalVisible(false)} title="Dataset label" visible={isModalVisible} onCancel={() => setIsModalVisible(false)}>
          {
            record.label && record.label.map((ll:String, index: number) => <Tag
            color={colors[Number(String(index)[String(index).length - 1])]}
            >{ll}</Tag>)
          }
        </Modal>
        <Table
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            ...pagination,
            showTotal: (total) => {
              return `Total: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <AddDatasetModal
        record={record}
        setRecord={setRecord}
        visible={addDatasetModelVisible}
        setVisible={setAddDatasetModelVisible}
        getList={() => fetchDatasets(pagination)}
      />
      <AddLabelModal
        record={record}
        setRecord={setRecord}
        visible={addLabelVisible}
        setVisible={setAddLableVisible}
        getList={() => fetchDatasets(pagination)}
      />
      <EditLabelModal
        record={record}
        setRecord={setRecord}
        visible={editLabelModalVisible}
        setVisible={setEditLabelModalVisible}
        getList={() => fetchDatasets(pagination)}
      />
      <DeleteLabelModal
        record={record}
        setRecord={setRecord}
        visible={deleteLabeModalVisible}
        setVisible={setDeleteLabeModalVisible}
        getList={() => fetchDatasets(pagination)}
      />
    </>
  )
}


export default Dataset;