import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import * as labelAPI from './labelAPI'
const initialState = {}

export const getLabelsAsync = createApiAsyncThunk<any>('datasets/getLabels', labelAPI.getLabels)

export const exportLabelAsync =createApiAsyncThunk<any>('datasets/exportLabels', labelAPI.exportLabels)

export const getAllLabelsAsync = createApiAsyncThunk<any>('datasets/getLabels/all', labelAPI.getAllLabels)

export const deleteLabelsAsync = createApiAsyncThunk<labelAPI.idPayload>('datasets/deleteLabels', labelAPI.deleteLabels)

export const getLabelAysnc = createApiAsyncThunk<labelAPI.idPayload>('datasets/getLabel', labelAPI.getLabel)

export const addLabelAysnc = createApiAsyncThunk<labelAPI.addPayload>('datasets/addLabel', labelAPI.addLabel)
export const deleteLabelAysnc = createApiAsyncThunk<labelAPI.idPayload>('datasets/deleteLabel', labelAPI.deleteLabel)
export const deleteLabelChildAysnc = createApiAsyncThunk<labelAPI.deletePayload>('datasets/deleteLabelChild', labelAPI.deleteLabelChild)

export const editLabelAsync = createApiAsyncThunk<labelAPI.editPayload>('datasets/editLabel', labelAPI.editLabel)

export const datasetSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {}
})

export default datasetSlice.reducer;
