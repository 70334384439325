import { useState, useEffect } from 'react'
import { Form, Input, Modal, Select, Button, message } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  addUserAsync
} from '../../reducers/users/usersSlice'
import { CopyOutlined, RedoOutlined } from '@ant-design/icons';
import './Users.scss'
import mobileCountryCodes from '../../config/mobileCountryCodes'
import generator from 'generate-password'
import md5 from 'js-md5'
const { Option } = Select;

interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const AddUserModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    props.getList()
  }
  const [mobileCountryCode, setMobileCountryCode] = useState('86')
  const selectBefore = (
    <Select disabled={!!props.record._id} value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
        <Option value={code.code}>{code.code}</Option> )
      }
    </Select>
  );
  const copyToClipboard = () => {
    const password = form.getFieldValue('password')
    if (window.clipboardData) {
      window.clipboardData.setData('text', password)
    }else{
      (function(s){
        document.oncopy= function (e: any) {
          e.clipboardData.setData('text',s)
          e.preventDefault()
          document.oncopy=null
        }
      })(password)
      document.execCommand('Copy')
    }
    return message.success('Copied successfully')
  }
  const setPassword = () => {
    form.setFieldsValue({
      password: generator.generate({
        length: 12,
        numbers: true
      })
    })
  }
  const onFinish = async() => {
    const params = form.getFieldsValue()
    params.id = props.record._id
    params.password = md5(params.password)
    params.mobileCountryCode = mobileCountryCode
    setLoading(true)
    const result = await dispatch(addUserAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg } = payload
      if (code === 0) {
        message.success('Successful operation');
        closeModal()
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  useEffect(() => {
    console.log(props)
    if (props.visible) {
      setPassword()
      form.setFieldsValue({
        username: props.record.username,
        mobile: props.record.mobile
      })
    }
  }, [props.visible])
  const layout = {
    labelCol: { span: 5},
  };
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          title={props.record._id ? `Set password: ${form.getFieldValue('username')}` :"Add User"}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Ok
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
        <Form.Item name="username" label="Username" rules={[{ required: true }]}>
          <Input disabled={!!props.record._id} />
        </Form.Item>
        <Form.Item name="mobile" label="Mobile" rules={[{ required: true }]}>
          <Input addonBefore={selectBefore} disabled={!!props.record._id} />
        </Form.Item>
        <div className="password-box">
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <div className="password-btn-group">
            <Button onClick={copyToClipboard} shape="circle" icon={<CopyOutlined />} >
            </Button>
            <Button onClick={() => setPassword()} shape="circle" icon={<RedoOutlined />} >
            </Button>
          </div>
        </div>
        </Modal>
      </Form>
    </>
  );
}

export default AddUserModal
