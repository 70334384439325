// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface datasetPayload {
  name: string;
  labels: any;
}

export function addDataset(payload: datasetPayload) {
  return api('/api/admin/datasets', 'POST', payload)
}


export function getDatasets(payload: any) {
  return api('/api/admin/datasets', 'GET', payload)
}

export function getAllDatasets(payload: any) {
  return api('/api/datasets/all', 'GET')
}


export interface idPayload {
  id: string;
}
export function getDataset(payload: idPayload) {
  return api('/api/dataset', 'GET', payload)
}


export interface idPayload {
  id: string;
}
export function deleteDatasets(payload: idPayload) {
  return api('/api/admin/datasets', 'DELETE', payload)
}


export interface lablPayload {
  id: string;
  name: string;
  expert: boolean;
  value?: string | undefined;
}
export function addDatasetLabel(payload: lablPayload) {
  return api('/api/admin/datasets/labels', 'POST', payload)
}




export function importData(payload: any) {
  return api('/api/admin/datasets/data', 'POST', payload, {}, true, 30 * 60 * 1000)
}


export interface editPayload {
  id: string;
  operation: string;
  name: string;

  rename?: string;

  expert?: boolean;
  value?: string | undefined;
}
export function editDatasetLabel(payload: editPayload) {
  return api('/api/admin/datasets/labels', 'PUT', payload)
}

export interface deletePayload {
  id: string;
  name: string;
}
export function deleteDatasetLabel(payload: deletePayload) {
  return api('/api/admin/datasets/labels', 'DELETE', payload)
}
