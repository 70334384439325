import React, { useState, useEffect } from 'react';
import Line from '@ant-design/charts/es/plots/line';

interface propsObject {
  data: Array<any>
}
const LineChart = (props: propsObject) => {
  const [data, setData] = useState([]);
  useEffect(() => {
  }, []);
  var config = {
    data: props.data,
    xField: 'createdAt',
    yField: 'value',
    seriesField: 'category',
    tooltip: {
      formatter: (datum: any) => {
        return { name: datum.category, value: datum.value + '%' };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };
  return <Line {...config} />;
};

export default LineChart;