import { Layout, PageHeader, Skeleton, List, Card, Button, Divider, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {fetchDBAsync } from '../../reducers/stat/statSlice'
import {selectAccount} from '../../reducers/account/accountSlice'
import config from '../../config/config'
import { DatabaseOutlined } from '@ant-design/icons';
const { Content } = Layout;
const DBFileList = () =>  {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const account = useAppSelector(selectAccount)
  useEffect(() => {
    fetchFiles()
  }, [])

  const fetchFiles = async() => {
    setLoading(true)
    const result = await dispatch(fetchDBAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.backups)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title="DB Backups"
        extra={[
          <Button key="2" onClick={() => fetchFiles()}>Refresh</Button>
        ]}
      />
      <Divider className="label-admin-divider" />
      <Content className="label-admin-content"
      style={{width: '100%', padding: '30px 30px'}}>
        <Skeleton active={true} loading={loading}>
            {
              data.map((item: string) => (
                <>
                  <a key={item} href={`${config.domain}/api/admin/download/db/${item}?token=${account.token}`} target="view_window">
                    <Card.Grid style={{width: 300}}>
                    <DatabaseOutlined /> {item}</Card.Grid>
                  </a>
                </>
              ))
            }
        </Skeleton>
      </Content>
    </>
  )
}


export default DBFileList;