import logo from '../logo.svg'
import './SignIn.scss'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal, Statistic, Card, Select  } from 'antd'
import Icon, { MailOutlined } from '@ant-design/icons'
import { accountPayload } from '../reducers/account/accountAPI'
import md5 from 'js-md5'
import { useAppDispatch } from '../app/hooks'
import {
  sendSMSCodeAsync,
  feachAccountAsync,
  feachAccountByTokenAsync,
  restore,
  restoreUser
} from '../reducers/account/accountSlice';
import mobileCountryCodes from '../config/mobileCountryCodes'
import ReCAPTCHA from 'react-google-recaptcha'
import { useState, useEffect, useRef } from 'react';
const { Option } = Select;

const SignIn = () => {
  let siteKey = '6LfOb6kfAAAAAO5nc2ESOoIoDQEngXhRE1kHur6_'
  const dispatch = useAppDispatch();
  const history = useHistory()
  const [form] = Form.useForm()
  const recaptchaRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState({
    captchaId: '',
    data: ''
  })
  const [mobileCountryCode, setMobileCountryCode] = useState('86')
  const [count, setCount] = useState(0)
  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
        <Option value={code.code}>{code.code}</Option> )
      }
    </Select>
  );
  const onFinish = async () => {
    const values = form.getFieldsValue()
    values.password = md5(values.password)
    const recaptchaValue = recaptchaRef.current?.getValue()
    values.mobileCountryCode = mobileCountryCode
    values['g-recaptcha-response'] = recaptchaValue
    setLoading(true)
    const result = await dispatch(feachAccountAsync({
      ...values
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        localStorage.setItem('token', data.token)
        localStorage.setItem('tokenExpiredAt', data.tokenExpiredAt.getTime())
        if (data.user.type === 'admin') {
          return history.push('/admin')
        }
        history.push('/work/all')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const setTimer = (value: number) => {
    setTimeout(() => {
      value = value -1
      setCount(value)
      if (value <= 0) {
        return
      }
      setTimer(value)
    }, 1000)
  }
  const sendSMSCode = async () => {
    setTimer(60)
    const result = await dispatch(sendSMSCodeAsync({
      mobileCountryCode: mobileCountryCode,
      mobile: form.getFieldValue('mobile')
    }))
    const { payload } = result
    if (payload) {
      const { data, code } = payload
      if (code === 0) {
        console.log(data)
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const signInByToken = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      const result = await dispatch(feachAccountByTokenAsync({}))
      const { payload } = result
      if (payload) {
        const { data, code, msg } = payload
        if (code === 0) {
          dispatch(restore({
            token: data.token,
            tokenExpiredAt: data.tokenExpiredAt.toString()
          }))
          dispatch(restoreUser({ user: data.user }))
          history.push('/admin')
        }
      }
    }
  }

  useEffect(() => {
    signInByToken()
  }, [])
  return (
    <Card hoverable style={{width: 650, margin: '100px auto 0'}}>

    <Form
      className="sign-form"
      form={form}
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
     <div className="logo-box">
        <img src={logo} className="App-logo" alt="logo" />
     </div>
      <Form.Item
        className="sign-input"
        label="Mobile"
        name="mobile"
        rules={[{ required: true, message: 'Please input your mobile!' }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        className="sign-input"
        label=" Verification code"
        name="code"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input className="" addonAfter={
          <div onClick={sendSMSCode} className='mail-btn'>
            {
              count > 0 ? <Statistic value={count} /> :  <MailOutlined />
            }
          </div>
      }
        />
      </Form.Item> */}

      <Form.Item
        className="sign-input"
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      {
        process.env.NODE_ENV  === 'production' && !loading ? (
          <Form.Item
            className="sign-input sign-captch"
            label="Captcha"
            // name="captcha"
            rules={[{ required: true, message: 'Please input captcha!' }]}
          >
            <ReCAPTCHA
              sitekey={siteKey}
              ref={recaptchaRef}
            />
          </Form.Item>): ''
      }
      <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
        <Button loading={loading} type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
    </Card>
  )
}

export default SignIn;
