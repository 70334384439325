import { message } from 'antd';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { createApiAsyncThunk } from '../thunk'

import { fetchAccount, fetchCaptcha, fetchAccountByToken, accountPayload, sendSMSCode } from './accountAPI';
let token = localStorage.getItem('token') || ''
let tokenExpiredAt = new Date(parseInt(localStorage.getItem('tokenExpiredAt') || ''))
if (tokenExpiredAt <= new Date() || tokenExpiredAt.toString() === 'Invalid Date') {
  token = ''
  tokenExpiredAt = new Date()
}
export interface AccountState {
  token: string;
  tokenExpiredAt: string;
  user?: any;
}
const initialState: AccountState = {
  token: token,
  tokenExpiredAt: tokenExpiredAt.toString(),
  user: {}
}

export const feachAccountByTokenAsync = createApiAsyncThunk<any>('account/fetchAccountByToken', fetchAccountByToken)

export const feachAccountAsync = createApiAsyncThunk<accountPayload>('account/fetchAccount', fetchAccount)

export const feachCaptchaAsync = createApiAsyncThunk<any>('account/feachCaptcha', fetchCaptcha)
export const sendSMSCodeAsync = createApiAsyncThunk<any>('account/sendSMSCode', sendSMSCode)

const getActionKey = (type: string): string => {
  const typeArr = type.split('/')
  typeArr.pop()
  const key = typeArr.join('/')
  return key
}
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    restore: (state, action: PayloadAction<AccountState>) => {
      state.token = action.payload.token
      state.tokenExpiredAt = action.payload.tokenExpiredAt
    },
    restoreUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user
    },
    signout (state) {
      state.token = ''
      state.tokenExpiredAt = ''
      state.user = {}
    }
  },
  extraReducers: (builder) => {
    console.log(builder)
    builder
    .addMatcher((action) => {
      return action.type.endsWith('/pending')
    }, (state, action) => {
      window.loading.status = 'loading';
      const key = getActionKey(action.type)
      message.loading({content: 'Loading..', key} )
    })
    .addMatcher((action) => {
      return action.type.endsWith('/fulfilled')
    }, (state, action) => {
      window.loading.status = 'idle';
      const key = getActionKey(action.type)
      message.destroy(key)
    })
    .addMatcher((action) => {
      return action.type.endsWith('/rejected')
    }, (state, action) => {
      window.loading.status = 'idle';
      const key = getActionKey(action.type)
      message.destroy(key)
    })
  },
});

export const selectAccount = (state: RootState) => state.account;
export const selectUser = (state: RootState) => state.account.user;
export const { restore, restoreUser, signout } = accountSlice.actions;

export default accountSlice.reducer;
