import { useState, useEffect } from 'react'
import { Form, Alert, Upload, Modal, Button, Space, message, Select, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../app/hooks'
import config from '../../config/config'
import {
  importDataAsync,
  getALlDatasetsAsync
} from '../../reducers/datasets/datasetSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
}
const EditDatasetLabelModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [datasets, setDatasets] = useState([])
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    form.resetFields()
    setFileList([])
  }
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const data = {
        'id': values.id,
        file: fileList[0]
      }
      setLoading(true)
      const result = await dispatch(importDataAsync(data))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          openNotification(data.total, data.insert, data.repeat, data.returnList.length)
          if (data.returnList.length) {
            if (data.type === 'json') {
              download(JSON.stringify(data.returnList))
            } else {
              exportSheet(data.returnList)
            }
          }
          closeModal()
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }
  const openNotification = (total: number, insert: number, repeat: number, failed: number) => {
    const key = `Successful operation`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        OK
      </Button>
    );
    notification.open({
      message: key,
      description: `${insert}/${total} new data, ${repeat}/${total} duplicates. ${failed} files update failed`,
      btn,
      key,
      duration: null
    });
  }

  useEffect(() => {
    fetchDataset()
  }, [])
  const fetchDataset = async() => {
    setLoading(true)
    const result = await dispatch(getALlDatasetsAsync({
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDatasets(data.datasets)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onChangeName = (e: any) => {
    form.setFieldsValue({ rename: e })
  };
  const layout = {
    labelCol: { span: 5},
  }
  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del))
    },
    beforeUpload: (file: any) => {
      let newFileList: Array<any> = [...fileList, file]
      setFileList(newFileList)
      return false;
    },
    fileList,
  };

  const exportSheet = (labels: Array<any>) => {
    let table
    let results: any[] = []
    let sheet: any = {}
    import ('../../utils/xlsx').then((xlsxUtil) => {
       import ('xlsx').then((XLSX) => {
        table = labels
        sheet.table = XLSX.utils.json_to_sheet(table);
        sheet.sheetName = 'all fields'
        results.push(sheet)
        const name = new Date().getTime()
        xlsxUtil.openDownloadDialog(xlsxUtil.sheet2blob(results), `export-${name}.csv`);
       })
    })
  }
  const download = (txt: string) => {
    let element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt))
      element.setAttribute('download', `${new Date().getTime()}_data.json`)
      element.click()
  }
  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          width={600}
          visible={props.visible}
          title="Import data"
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit"  onClick={onFinish} disabled={fileList.length === 0} loading={loading} type="primary">
              {loading ? 'Uploading' : 'Start Upload'}
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="id" label="Dataset name" rules={[{ required: true }]}>
            <Select onChange={onChangeName}>
              {
                datasets
                .map((dataset: any) => <Select.Option key={dataset._id} value={dataset._id}>{dataset.name}</Select.Option>)
              }
            </Select>
          </Form.Item>

          <>
            <Upload {...uploadProps}>
              <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </>
          <Alert
            style={{margin: '10px 0 0'}}
            showIcon
            message="Note:"
            description="
            Select a csv file containing new records, For reference, you can download this template file.
            "
            type="info"
            action={
              <Space direction="vertical">

                <a href={`${config.domain}/static/template.csv`} target="view_window">
                  template.csv
                </a>
                <a href={`${config.domain}/static/template.json`} target="view_window">
                  template.json
                </a>
              </Space>
            }
          />
        </Modal>
      </Form>
    </>
  );
}

export default EditDatasetLabelModal
