import { useState, useEffect } from 'react'
import { Form, Input, Radio, Switch, Modal, Button, message, Select } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  editDatasetLabelAsync,
  getDatasetAsync
} from '../../reducers/datasets/datasetSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const EditDatasetLabelModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [valueType, setType] = useState('string')
  const [operation, setOperation] = useState('rename')
  const [useEval, setUseEval] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataset, setDataset] = useState({} as any)
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    props.setRecord({})
    props.getList()
    form.resetFields()
  }
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const params = values
      params.id = props.record._id
      params.operation = operation
      params.useEval = useEval
      params.valueType = valueType
      setLoading(true)
      const result = await dispatch(editDatasetLabelAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation');
          closeModal()
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }

  useEffect(() => {
    if (props.visible) {
      fetchDataset()
    }
  }, [props.record._id])
  const fetchDataset = async() => {
    if (!props.record._id) {
      return
    }
    setLoading(true)
    const result = await dispatch(getDatasetAsync({
      id: props.record._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDataset(data.dataset)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onChange = (e: any) => {
    console.log('radio checked', e.target.value)
    setType(e.target.value)
    form.setFieldsValue({ value: '' })
  };
  const onSwitchChange = (checked: boolean) => {
    setUseEval(checked);
    setType('string');
    form.setFieldsValue({ value: '' })
  };
  const onChangeName = (e: any) => {
    form.setFieldsValue({ rename: e })
  };
  const onChangeOperate = (e: any) => {
    setOperation(e.target.value)
  };
  const layout = {
    labelCol: { span: 5},
  }
  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          visible={props.visible}
          title="Edit Label"
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              Ok
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Form.Item name="name" label="Label name" rules={[{ required: true }]}>
            <Select onChange={onChangeName}>
              {
                dataset.label && dataset.label
                .map((label: string) => <Select.Option key={label} value={label}>{label}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item label="Operation" rules={[{ required: true }]}>
            <Radio.Group onChange={onChangeOperate} value={operation}>
              <Radio value='rename'>Rename</Radio>
              <Radio value='setValue'>Set value</Radio>
            </Radio.Group>
          </Form.Item>
          {
            operation === 'rename' ?
             (<Form.Item name="rename"
                label="Rename" rules={[{ required: true }]}>
                <Input />
              </Form.Item>) : (<>
                <Form.Item label="Expert?">
                  <Switch onChange={onSwitchChange} defaultChecked={useEval} checkedChildren='expert' unCheckedChildren='value' />
                </Form.Item>
                {
                  !useEval ? (
                    <Form.Item label="Value type">
                      <Radio.Group onChange={onChange} value={valueType}>
                        <Radio value='string'>String</Radio>
                        <Radio value='number'>Number</Radio>
                      </Radio.Group>
                    </Form.Item>) : ''
                }
                <Form.Item name="value" label="Label value">
                  <Input type={valueType} />
                </Form.Item>
              </>)
          }
        </Modal>
      </Form>
    </>
  );
}

export default EditDatasetLabelModal
