import React, { useState, useEffect } from 'react';
import Pie from '@ant-design/charts/es/plots/pie';

interface propsObject {
  used: number
}
const DemoPie = (props: propsObject) => {
  const data = [
    {
      type: 'Used',
      value: props.used,
    },
    {
      type: 'Not used',
      value: 100 - props.used,
    },
  ];
  const config = {
    appendPadding: 10,
    data: data,
    showTitle: true,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    tooltip: {
      formatter: (datum: any) => {
        return { name: datum.type, value: parseInt(datum.value) + '%' };
      },
    },
    label: {
      type: 'inner',
      offset: '-30%',
      content: function content(_ref: any) {
        var percent = _ref.percent;
        return ''.concat((percent * 100).toFixed(0), '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  };
  return <Pie width={200} height={200} autoFit={false} {...config} legend={false} />;
};

export default DemoPie;