import { Layout, PageHeader, Skeleton, Space, Card, Button, Divider, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks'
import { CloudUploadOutlined, DatabaseOutlined, FileZipOutlined, AlignLeftOutlined, CloudServerOutlined} from '@ant-design/icons';
import {fetchOSStatAsync } from '../../reducers/stat/statSlice'
import Chart from './Chart'
import Pie from './Pie'
import config from '../../config/config'
import dayjs from 'dayjs'
import ImportDataModal from './ImportDaraModal'
import { Link  } from 'react-router-dom';
const { Content } = Layout;
const { Option } = Select;
const Maintenance = () =>  {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(true)
  const [cpu, setCpu] = useState(0)
  const [memory, setMemory] = useState(0)
  const [disk, setDisk] = useState(0)
  const [importVisible, setImportVisible] = useState(false)
  const [startAt, setStartAt] = useState(dayjs().startOf('day'))
  const [endAt, setEndAt] = useState(dayjs().endOf('day'))
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })
  useEffect(() => {
    fetchStat()
  }, [startAt, endAt])

  const fetchStat = async() => {
    setLoading(true)

    const result = await dispatch(fetchOSStatAsync({
      startAt: startAt.toDate(),
      endAt: endAt.toDate()
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        data.cpu = Number(data.cpu)
        data.disk = Number(data.disk)
        data.memory = Number(data.memory)
        console.log(data)
        setCpu(data.cpu)
        setMemory(data.memory)
        setDisk(data.disk)
        const cpuData = data.stats.map((stat: any) => {
          stat.cpu = Number(stat.cpu)
          stat.disk = Number(stat.disk)
          stat.memory = Number(stat.memory)
          return {
            value: stat.cpu,
            createdAt: dayjs(stat.createdAt).format("YYYY-MM-DD HH:mm"),
            category: 'Cpu'
          }
        })
        const memoryData = data.stats.map((stat: any) => {
          return {
            value: stat.memory,
            createdAt: dayjs(stat.createdAt).format("YYYY-MM-DD HH:mm"),
            category: 'Memory'
          }
        })
        const diskData = data.stats.map((stat: any) => {
          return {
            value: stat.disk,
            createdAt: dayjs(stat.createdAt).format("YYYY-MM-DD HH:mm"),
            category: 'Disk'
          }
        })
        setData([].concat(cpuData, memoryData, diskData))
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const handleChange = (value: 'day' | 'week' | 'month' | 'year') => {
    setStartAt(dayjs().startOf(value))
    setEndAt(dayjs().endOf(value))
  }
  return (
    <>
      <PageHeader
        className="label-admin-page-header"
        title="Maintenance"
        extra={[
          <Button key="1" onClick={() => fetchStat()}>Refresh</Button>,
          <Select key="2"  defaultValue="day" style={{ width: 200 }} onChange={handleChange}>
            <Option key="day" value="day">Daily activity</Option>
            <Option key="week" value="week">weekly activity</Option>
            <Option key="month" value="month">Monthly activity</Option>
            {/* <Option key="quarter" value="quarter">Quarterly activity</Option> */}
            <Option key="year" value="year">Yearly activity</Option>
          </Select>
        ]}
      />
      <Divider className="label-admin-divider" />
      <Content className="label-admin-content" style={{width: '100%', padding: '30px 30px'}}>
      <Skeleton active={true} loading={loading}>
        <div style={{width: '90%', margin: '0px auto'}}>
          <Chart data={data} />
        </div>
        <div style={{ width: '100%', display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center'}}>
            <Pie used={cpu}/>
            <h2>CPU</h2>
          </div>
          <div style={{flex: '1', textAlign: 'center'}}>
            <Pie used={memory}/>
            <h2>Memory</h2>
          </div>
          <div style={{flex: '1', textAlign: 'center'}}>
            <Pie used={disk}/>
            <h2>Disk</h2>
          </div>
        </div>
        </Skeleton>
        <Space wrap={true} >
          <Link to="/admin/maintenance/log">
            <Card style={{ width: 300 }}>
              <AlignLeftOutlined /> <span>Logs</span>
            </Card>
          </Link>
          <Link to="/admin/maintenance/db">
            <Card style={{ width: 300 }}>
            <FileZipOutlined /> <span>Backups</span>
            </Card>
          </Link>
          <a href="https://s3.console.aws.amazon.com/s3/buckets/labeladmin?region=ap-southeast-2&tab=objects" target="view_window">
            <Card  style={{ width: 300 }}>
              <CloudServerOutlined /> <span>S3 Management</span>
            </Card>
          </a>
          <a href={`${config.domain}/mongo_express`} target="view_window">
          <Card style={{ width: 300 }}>
            <DatabaseOutlined /> <span>Database Mgmt</span>
          </Card>
          </a>
          <Card style={{cursor: 'pointer', width: 300}} onClick={() => setImportVisible(true)}>
            <CloudUploadOutlined /> <span>import data</span>
          </Card>
        </Space>

        <ImportDataModal
          visible={importVisible}
          setVisible={setImportVisible}
        />
      </Content>
    </>
  )
}


export default Maintenance;