import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getUsers,
  addUser,
  deleteUser,
  lockUser,
  unlockUser,
  addSearch,
  renameSearch,
  deleteSearch,
  setIconSearch,
  searchPayload,
  userPayload,
  idPayload,
  resetPassword,
  putUserPremissions,
  putUserPremissionsPayload
} from './usersAPI';
const initialState = {}

export const addUserAsync = createApiAsyncThunk<userPayload>('users/addUser', addUser)

export const getUsersAsync = createApiAsyncThunk<any>('users/getUsers', getUsers)

export const deleteUserAsync = createApiAsyncThunk<idPayload>('users/deleteUser', deleteUser)

export const lockUserAsync = createApiAsyncThunk<idPayload>('users/lockUser', lockUser)

export const unlockUserAsync = createApiAsyncThunk<idPayload>('users/unlockUser', unlockUser)

export const putUserPremissionsAsync = createApiAsyncThunk<putUserPremissionsPayload>('users/putUserPremissions', putUserPremissions)

export const addSearchAsync = createApiAsyncThunk<searchPayload>('users/addSearch', addSearch)
export const renameSearchAsync = createApiAsyncThunk<searchPayload>('users/renameSearch', renameSearch)
export const deleteSearchAsync = createApiAsyncThunk<searchPayload>('users/deleteSearch', deleteSearch)
export const setIconSearchAsync = createApiAsyncThunk<searchPayload>('users/setIconSearch', setIconSearch)
export const resetPasswordAsync = createApiAsyncThunk<searchPayload>('users/resetPassword', resetPassword)
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {}
})

export default usersSlice.reducer;
